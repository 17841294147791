@import "@/styles/_mixins.scss";

.container {
  height: 100vh;
  width: 100vw;

  .content {
    background-position: 50% 90%;
    background: url("/images/404.png");
    background-repeat: no-repeat;
    background-size: contain;
    min-height: calc(env(--space-200) * 2.08);

    @include responsiveMinWidth(phone) {
      background-position: center;
      align-items: center;
      justify-content: center;

    }

    .btnsWrapper {
      width: 100%;

      a {
        width: 100%;
      }

      @include responsiveMinWidth(phone) {
        flex-direction: row;

        >* {
          min-width: env(--space-144);
          max-width: env(--space-144);
        }
      }

    }
  }



}
